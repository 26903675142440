import React from "react";
import {Link} from "gatsby";
import * as styles from "./portfolio_list_item.module.scss";
import {GatsbyImage} from "gatsby-plugin-image";

export default function PortfolioListItem(props) {
    return (
        <li className={styles.listItem}>
            <Link className={styles.link} key={props.title} to={props.slug}>
                <h3>{props.title}</h3>
                <GatsbyImage alt={props.title} image={props.image} />
            </Link>
        </li>
    )
}