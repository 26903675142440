import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import PortfolioListItem from "../components/portfolio_list_item";
import * as styles from "./portfolio.module.scss"
import {getImage} from "gatsby-plugin-image";

export default function Portfolio({data}) {
    return (
        <Layout>
            <ul className={styles.list}>
                {data.allContentfulApDrawing.edges.map(edge => {
                    const image = getImage(edge.node.picture)
                    return (
                        <PortfolioListItem key={edge.node.title} title={edge.node.title} slug={edge.node.slug} image={image} />
                    )
                    }
                )}
            </ul>
        </Layout>
    )
}

export const pageQuery = graphql`
query MyQuery {
  allContentfulApDrawing {
    edges {
      node {
        slug
        title
        picture {
          gatsbyImageData (width: 300)
          title
        }
      }
    }
  }
}
`